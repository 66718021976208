import styled from 'styled-components';

const Container = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
  button {
    align-self: flex-end;
  }
`;

const Fields = styled.div`
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  margin-bottom: 40px;
  padding: 10px 0px;
`;

const SelectsContainer = styled.div`
  display: flex;
  > div:first-child {
    flex: 1;
    max-width: calc(100% - 110px);
  }

  @media (max-width: 768px) {
    > div:first-child {
      max-width: calc(100% - 70px);
    }
  }

  @media (max-width: 525px) {
    > div:first-child {
      max-width: calc(100% - 50px);
    }
  }
`;

const Swap = styled.div`
  align-self: center;
  padding: 20px;
  margin: 20px;
  :hover {
    cursor: pointer;
    background-color: #ededed;
    border-radius: 110px;
  }
  transform: rotate(${(props) => (props.direction === 'direct' ? '180deg' : '360deg')});
  transition: transform linear 0.5s;

  @media (max-width: 768px) {
    padding: 20px;
    margin: 0px;
    transform: rotate(${(props) => (props.direction === 'direct' ? '180deg' : '360deg')}) scale(0.8);
  }

  @media (max-width: 525px) {
    padding: 10px;
    transform: rotate(${(props) => (props.direction === 'direct' ? '180deg' : '360deg')}) scale(0.6);
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: #E5E5E5;
`;

export {
  Container,
  Fields,
  SelectsContainer,
  Swap,
  Divider,
};
