import React from 'react';
import PropTypes from 'prop-types';

import { FindBusForm } from 'containers';
import { Ticket } from 'components';
import { Button, LoadingIndicator } from 'components/shared';

import {
  Container,
  Tickets,
  TicketWrapper,
  TicketInfo,
  NoData,
} from './styles';

const Search = ({
  isLoading,
  isInitial,
  transfers,
  onTransferSelect,
}) => (
  <Container>
    <FindBusForm isTransfersLoading={isLoading} />
    <Tickets>
      {isLoading
        ? <LoadingIndicator overlay />
        : (
          <>
            {transfers.length
              ? transfers.map((transfer, index) => (
                <TicketWrapper key={transfer.id}>
                  <Ticket
                    header={`${transfer.route.number} ${transfer.route.name}`}
                    from={{
                      city: transfer.from.city.name,
                      time: transfer.from.time,
                      stop: transfer.from.name,
                    }}
                    to={{
                      city: transfer.to.city.name,
                      time: transfer.to.time,
                      stop: transfer.to.name,
                    }}
                    price={`от ${transfer.price}р.`}
                    color={index % 2 === 0 ? 'blue' : 'purple'}
                  />
                  <TicketInfo amount={transfer.amount}>
                    <Button
                      disabled={transfer.amount <= 0}
                      onClick={() => onTransferSelect(transfer)}
                    >
                      Выбрать
                    </Button>
                    <span>
                      {transfer.limit.showAmount
                        && transfer.amount > 0 && `Свободно мест: ${transfer.amount}`}
                      {transfer.limit.showAmount
                        && transfer.amount <= 0 && 'Мест нет'}
                    </span>
                  </TicketInfo>
                </TicketWrapper>
              ))
              : !isInitial && <NoData>Актуальные рейсы не найдены</NoData>}
          </>
        )}
    </Tickets>
  </Container>
);

Search.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isInitial: PropTypes.bool.isRequired,
  transfers: PropTypes.array,
  onTransferSelect: PropTypes.func.isRequired,
};

Search.defaultProps = {
  transfers: [],
};

export default Search;
